import React from 'react';
import { useNavigate, useParams, createSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Loader from 'src/ui/components/Loader';

import { useAppDispatch, useAppSelector } from 'src/store';
import chatThunksV2 from './storeV2/chatThunksV2';
import { UserPermissionsENUM } from 'src/types';
import type { IChannel, IMessage, IUser, MessageType } from 'src/types';
import { chatSliceActions } from './store/Chat.reducer';
import { ROUTES, SECOND } from 'src/utils/constants';
import type { UserTypingResponseType } from 'src/api/ws/chatWs';
import chatWs from 'src/api/ws/chatWs';
import { chatSliceV2Actions } from './storeV2/chatSliceV2';
import { useOnlineCheck, useUser } from 'src/utils/hooks/general';
import chatScrollController from './utils/chatScrollController';
import chatSelectorsV2 from './storeV2/chatSelectorsV2';
import chatWs_v2 from './api/chatWs_v2';
import { t } from 'src/utils/getTranslation';
import getFullName from 'src/utils/getFullName';
import { useGetUsersQuery } from 'src/api/rtkQuery/usersQuery';
import { closeProfileDrawer } from 'src/ui/containers/UserProfileDrawer/UserProfileDrawer';
import channelApi_V2 from './api/channelApi_V2';
import { validateUserPermissions } from 'src/ui/components/Protector';
import storage from 'src/utils/storage';
import { soundControllerEvent } from 'src/utils/hooks/useSoundController';
import useChannelNotificationStatus from 'src/utils/hooks/useChannelNotificationStatus';
import { ChannelNotificationStatusENUM } from 'src/types/userTypes';
import { SpecialMentionTypeENUM } from './types/quillTypes';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';
import { useDidUpdate } from 'src/utils/hooks/useDidUpdate';
import { ElectronAPI } from 'src/utils/electronAPI';

let newMessageAudio = new Audio('/newMessage.mp3');
try {
  if (localStorage.getItem('sheep_enable_sound') === 'trick') {
    newMessageAudio = new Audio('/newMessage4.mp3');
  }
  if (localStorage.getItem('my_lovely_sound') === 'dog') {
    newMessageAudio = new Audio('/newMessage3.mp3');
  }
  if (localStorage.getItem('my_lovely_sound') === 'icq') {
    newMessageAudio = new Audio('/newMessage5.mp3');
  }
} catch (err) {
  console.warn('wrong trick');
}
newMessageAudio.volume = 0.5;

export const useNewChatMessageSound = () => {
  const user = useUser();
  const currentChannel = useCurrentChannel();
  const currentChannelRef = React.useRef(currentChannel);
  currentChannelRef.current = currentChannel;
  const soundSettingStatus = React.useRef(storage.soundController.get());
  const { allChannelNotificationStatuses, getChannelNotificationStatusById } = useChannelNotificationStatus();

  const openedThreadParentId = useAppSelector(({ chatPageV2 }) => chatPageV2.openedThread.parentMessageId);
  const openedThreadParentIdRef = React.useRef(openedThreadParentId);
  openedThreadParentIdRef.current = openedThreadParentId;

  const isSocketConnected = useAppSelector((state) => state.main.socketData.isConnected);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    const unsubscribeFromNew = chatWs_v2.subscribeOnNewMessage((data) => {
      const isOpenedChannel = window.location.pathname === ROUTES.chat.createPath(data.meta.channelId);
      const isWindowFocused = document.hasFocus();
      const isThread = Boolean(data.payload.parentMessage);
      const isThreadOpened = isThread ? openedThreadParentIdRef.current === data.payload.parentMessage!.messageId : false;

      const channelNotificationsStatus = getChannelNotificationStatusById(data.meta.channelId);

      if (channelNotificationsStatus === ChannelNotificationStatusENUM.disabled) return;

      if (channelNotificationsStatus === ChannelNotificationStatusENUM.mentionOnly) {
        const isUserInMentions = data.meta.userMentionUserIds.some((id) => {
          return id === user.userId || id === SpecialMentionTypeENUM.channel;
        });
        if (!isUserInMentions) return;
      } else if (isThread && !data.meta.userAddedToThreadIds.includes(user.userId)) return;

      if (
        (isOpenedChannel && isWindowFocused && (!isThread || isThreadOpened)) ||
        user.userId === data.payload.authorId
      ) {
        return;
      }

      try {
        if (soundSettingStatus.current === 'off') {
          return;
        }
        newMessageAudio.currentTime = 0;
        newMessageAudio.play().catch(() => null);
        newMessageAudio.addEventListener('error', () => null);
      } catch {
        //
      }
    });

    return () => {
      unsubscribeFromNew();
    };
  }, [user, isSocketConnected, allChannelNotificationStatuses]);

  React.useEffect(() => {
    return soundControllerEvent.subscribe(({ soundSettingStatus: status }) => {
      soundSettingStatus.current = status;
    });
  }, []);
};

interface IWihtAbortFunc extends Promise<unknown> {
  abort: () => void;
}

export const useChannels = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const { t } = useTranslation('errors');
  const navigate = useNavigate();
  const currentChannel = useCurrentChannel();
  const currentChannelRef = React.useRef(currentChannel);
  currentChannelRef.current = currentChannel;

  const socketData = useAppSelector((state) => state.main.socketData);
  const channelDetailsObject = useAppSelector((state) => state.chatPageV2.channelDetailsObject);
  const openedThread = useAppSelector(({ chatPageV2 }) => chatPageV2.openedThread);

  const dispatchPromise = React.useRef<{ [id: string]: IWihtAbortFunc }>({});
  const revalidateAppDataTrigger = useAppSelector((state) => state.main.revalidateAppDataTrigger);

  const handleInitializeChatData = () => {
    dispatch(chatThunksV2.getMyChannels());
    const channelWithDetailsIds = Object.keys(channelDetailsObject);
    if (channelWithDetailsIds.length) {
      channelWithDetailsIds.forEach((channelId) => {
        dispatchPromise.current[channelId]?.abort();
        dispatchPromise.current[channelId] = dispatch(chatThunksV2.getChannelData({
          channelId: +channelId,
          scrollTargetMessageId: undefined,
          shouldRefresh: true,
        }));
      });
    }

    if (openedThread.parentMessageId && currentChannel?.channelId) {
      dispatch(chatThunksV2.openThread({
        channelId: currentChannel.channelId!,
        parentMessageId: openedThread.parentMessageId,
        shouldRefresh: true,
      }));
    }
  };

  useDidUpdate(() => {
    handleInitializeChatData();
  }, [revalidateAppDataTrigger]);

  React.useEffect(() => {
    const isNeedSocketDataRefetch = socketData.isConnected && socketData.connectionAttemptCount > 1;

    if (ElectronAPI.systemStateInSuspendedMode) {
      return;
    }

    if (isNeedSocketDataRefetch) {
      console.info('>>>isNeedSocketDataRefetch<<', { date: new Date().toLocaleString(), socketData });
      dispatch(mainSliceActions.setSocketAttemptCount(1));
      handleInitializeChatData();
    }
  }, [dispatch, socketData, currentChannel?.channelId]);

  React.useEffect(() => {
    dispatch(chatThunksV2.getMyChannels());
  }, [dispatch]);

  React.useEffect(() => {
    // const unsubscribeFromOld = chatWs.subscribeOnNewMessage((newMessage) => {
    //   dispatch(chatSliceV2Actions.handleNewMessage_old({
    //     message: newMessage,
    //     chatId: newMessage.channelId!,
    //     isMyMessage: newMessage.authorId === user.userId,
    //   }));
    // });

    const unsubscribeFromNew = chatWs_v2.subscribeOnNewMessage((data) => {
      dispatch(chatSliceV2Actions.handleNewMessage({
        message: data.payload,
        channelId: data.meta.channelId,
        meta: data.meta,
        user,
      }));
    });

    const unsubscribeOnVisibleChannel = chatWs_v2.subscribeOnVisibleChannel(({ payload }) => {
      dispatch(chatSliceV2Actions.handleChannelBecomeVisible(payload));
    });

    const unsubscribeFromNewReadMessages = chatWs_v2.subscribeOnReadStatusChange((data) => {
      dispatch(chatSliceV2Actions.handleNewReadMessages(data.payload));
    });

    const unsubscribeFromReactions = chatWs_v2.subscribeOnReaction(({ payload, meta }) => {
      dispatch(chatSliceV2Actions.toggleReaction({
        channelId: meta.channelId,
        parentMessageId: meta.parentId,
        messageId: meta.messageId,
        reaction: payload.reaction,
        actionType: payload.actionType,
      }));
    });

    const unsubscribeFromOldChannelOwnerReassigned = chatWs_v2.subscribeOnOldChannelOwnerReassigned(({ channelId, newOwner }) => {
      dispatch(chatSliceV2Actions.handleChannelUpdate({
        channel: {
          channelId,
          owner: newOwner,
        },
      }));
    });

    return () => {
      // unsubscribeFromOld();
      unsubscribeFromNew();
      unsubscribeFromNewReadMessages();
      unsubscribeFromReactions();
      unsubscribeFromOldChannelOwnerReassigned();
      unsubscribeOnVisibleChannel();
    };
  }, [dispatch, user, socketData.isConnected]);

  React.useEffect(() => {
    return chatWs.subscribeOnNewMention((data) => {
      dispatch(chatSliceV2Actions.handleNewMention(data));
    });
  }, [dispatch, socketData.isConnected]);

  React.useEffect(() => {
    return chatWs.subscribeOnNewActionMessage((message) => {
      dispatch(chatSliceV2Actions.handleNewMessage({
        message,
        channelId: message.channelId as number,
        meta: {
          userAddedToThreadIds: [],
          userMentionUserIds: [],
          type: 'channel',
          channelId: message.channelId as number,
        },
        user,
      }));
    });
  }, [dispatch, user, socketData.isConnected]);

  React.useEffect(() => {
    return chatWs.subscribeOnChannelUpdate((data) => {
      dispatch(chatSliceV2Actions.handleChannelUpdate({ channel: data }));
    });
  }, [dispatch, socketData.isConnected]);

  const goToDefaultChannel = React.useCallback(() => {
    navigate(ROUTES.chat.createPath());
  }, [navigate]);

  React.useEffect(() => {
    return chatWs.subscribeOnChannelArchive(async (data) => {
      if (currentChannel?.channelId === data.channelId) {
        goToDefaultChannel();
      }

      const canEditArchivedChannels = user.permissions?.some((per) => per.value === UserPermissionsENUM.chat__manageAllArchivedChannels);
      if (data.owner?.userId !== user.userId && !canEditArchivedChannels) {
        dispatch(chatSliceV2Actions.handleChannelDelete({ channelId: data.channelId }));
        return;
      }
      dispatch(chatSliceV2Actions.handleChannelUpdate({ channel: data }));
    });
  }, [dispatch, user, goToDefaultChannel, currentChannel, socketData.isConnected]);

  React.useEffect(() => {
    return chatWs.subscribeOnChannelUnarchive(async (data) => {
      const channelData = await chatWs.getChannel(data.channelId, { withMeta: true });

      dispatch(chatSliceV2Actions.handleChannelUpdate(channelData));
    });
  }, [dispatch, socketData.isConnected]);

  React.useEffect(() => {
    return chatWs.subscribeOnChannelDelete(async (data) => {
      if (currentChannel?.channelId === data.channelId) {
        goToDefaultChannel();
      }

      dispatch(chatSliceV2Actions.handleChannelDelete(data));
    });
  }, [dispatch, currentChannel, goToDefaultChannel, socketData.isConnected]);

  React.useEffect(() => {
    return chatWs.subscribeOnNewChannel(async (data) => {
      if (data.creator?.userId === user.userId) {
        return;
      }

      try {
        const { channel, channelMeta } = await chatWs.getChannel(
          data.channelId,
          { withMeta: true },
        );

        dispatch(chatSliceV2Actions.handleNewChannel({ channel, meta: channelMeta }));
        dispatch(chatSliceActions.addChannel(channel));
      } catch (err) {
        toast.error(t('server.internal.unexpected'));

        console.error('Failed to get channel\'s data', err);
      }
    });
  }, [dispatch, user, t, socketData.isConnected]);

  React.useEffect(() => {
    return chatWs.subscribeOnRemovedUser((data) => {
      const withMe = Boolean(data.userToChannels?.find(({ userId }) => userId === user.userId));
      const hasCurrentUserPermissions = user.permissions?.some((perm) => perm.value === UserPermissionsENUM.chat__editAnyChannelMembers);
      const conditionForDefaultUsers = !withMe && currentChannel?.channelId === data.channelId;

      if (!hasCurrentUserPermissions && conditionForDefaultUsers) {
        goToDefaultChannel();
      }

      dispatch(chatSliceV2Actions.handleChannelUsersUpdate({
        channel: data,
        withMe: withMe || !!hasCurrentUserPermissions,
      }));
    });
  }, [dispatch, user, currentChannel, goToDefaultChannel, socketData.isConnected]);

  React.useEffect(() => {
    return chatWs.subscribeOnOwnerChange(async (data) => {
      const { channel } = await chatWs.getChannel(
        data.channelId,
        { withMeta: false },
      );

      dispatch(chatSliceV2Actions.handleChannelUpdate({ channel }));
    });
  }, [dispatch, user, currentChannel, goToDefaultChannel, socketData.isConnected]);

  React.useEffect(() => {
    const unsubscribeFromNewMentionV2 = chatWs.subscribeOnNewMentionV2(({ channelId }) => {
      dispatch(chatSliceV2Actions.handleNewMention({ channelId }));
    });

    const updateChannelMeta = async ({ channelId }: { channelId: number }) => {
      const { channel, channelMeta } = await chatWs.getChannel(
        channelId,
        { withMeta: true },
      );

      dispatch(chatSliceV2Actions.handleChannelUpdate({ channel, channelMeta }));
    };

    const unsubscribeFromRemovedMentionV2 = chatWs.subscribeOnRemovedMentionV2(updateChannelMeta);
    const unsubscribeFromDeletedMessage = chatWs.subscribeOnDeletedMessage((data) => {
      updateChannelMeta({
        channelId: data.channelId,
      });

      dispatch(chatSliceV2Actions.deleteMessage({
        channelId: data.channelId,
        messageId: data.messageId,
        parentMessageId: data.parentMessageId,
      }));
    });

    return () => {
      unsubscribeFromNewMentionV2();
      unsubscribeFromRemovedMentionV2();
      unsubscribeFromDeletedMessage();
    };
  }, [dispatch, socketData.isConnected]);

  React.useEffect(() => {
    const unsubscribeFromUpdatedMessage = chatWs.subscribeOnGetUpdatedMessage((message) => {
      if (!message.channelId) {
        return;
      }

      dispatch(chatSliceV2Actions.updateMessage({
        channelId: message.channelId,
        messageData: message,
        messageId: message.messageId,
        parentMessageId: ((message.parentId || message.parentMessage?.messageId)) ?? undefined,
      }));
    });
    return () => {
      unsubscribeFromUpdatedMessage();
    };
  }, [dispatch, socketData.isConnected]);
};

export const useMainChannel = () => {
  const channelsObject = useAppSelector(({ chatPageV2 }) => chatPageV2.channelsObject);
  const user = useUser();
  const pathData = useChatPathData();
  const data = React.useMemo(() => {
    const mainChannel = channelsObject[user.company!.mainChannelId];

    return {
      mainChannel,
      isMain: pathData.channelId === mainChannel?.channelId,
    };
  }, [channelsObject, user, pathData]);

  return data;
};

export const useCurrentChannel = () => {
  const pathData = useChatPathData();

  const channelsObject = useAppSelector(({ chatPageV2 }) => chatPageV2.channelsObject);

  const currentChannel: IChannel | null = React.useMemo(() => {
    if (!pathData.channelId) {
      return null;
    }

    const channel = channelsObject[pathData.channelId];

    return channel || null;
  }, [pathData.channelId, channelsObject]);

  React.useEffect(() => {
    if (!currentChannel) {
      return;
    }

    chatScrollController.setChannelId(currentChannel.channelId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannel?.channelId]);

  return currentChannel;
};

export const useFeedActions = () => {
  const dispatch = useAppDispatch();
  const timeoutRef = React.useRef<number | undefined>();
  const oldestMessageCreationDate = React.useRef<Date | null>(null);

  const currentChannel = useCurrentChannel();

  const readMessage = React.useCallback((message: MessageType) => {
    const messageCreationDate = new Date(message.createdAt);

    if (messageCreationDate <= (oldestMessageCreationDate.current as Date)) {
      return;
    }
    oldestMessageCreationDate.current = messageCreationDate;
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      dispatch(chatThunksV2.markMessageAsRead({ message, channelId: currentChannel!.channelId }));
      oldestMessageCreationDate.current = null;
    }, SECOND) as unknown as number;
  }, [dispatch, currentChannel]);

  return {
    readMessage,
  };
};

export const useChatPathData = () => {
  const params = useParams<{ chatId?: string }>();

  const pathData = React.useMemo(() => {
    const results = {
      channelId: null as number | null,
      isSerach: false,
      pathParameter: params.chatId,
      isValidPath: true,
      newDm: false,
      threads: false,
    };

    if (!params.chatId) {
      results.isValidPath = false;
      return results;
    }

    if (params.chatId === 'search') {
      results.isSerach = true;
      return results;
    }

    if (params.chatId === 'new-dm') {
      results.newDm = true;
      return results;
    }

    if (params.chatId === 'threads') {
      results.threads = true;
      return results;
    }

    const channelId = +params.chatId;
    if (!/^\d+$/.test(params.chatId) || channelId <= 0) {
      results.isValidPath = false;
      return results;
    }

    results.channelId = channelId;

    return results;
  }, [params.chatId]);

  return pathData;
};

export const useChannelDetails = () => {
  const pathData = useChatPathData();
  const channelData = useAppSelector((store) => chatSelectorsV2.selectChannelData(store, { channelId: pathData.channelId! }));

  return channelData;
};

export const useDefaultChannelId = () => {
  const defaultChannelId = useAppSelector(({ main }) => main.user!.company!.mainChannelId);

  return defaultChannelId;
};

export const useUserForMention = () => {
  const checkOnlineStatus = useOnlineCheck();
  const checkOnlineStatusRef = React.useRef(checkOnlineStatus);
  checkOnlineStatusRef.current = checkOnlineStatus;

  const { data, fulfilledTimeStamp } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data, fulfilledTimeStamp }) => ({ data, fulfilledTimeStamp }),
  });

  const getUsersForMention = React.useCallback(() => {
    return data?.payload.map((user) => ({
      id: user.userId,
      type: 'user' as const,
      value: getFullName(user),
      isOnline: checkOnlineStatusRef.current(user.userId),
      jobTitle: user.jobPosition?.name || t('hrmEmployees:userTable.actionTools.dataNotFound'),
      avatarLink: user.avatarMediaItem?.thumbLink || user.avatarMediaItem?.link || null,
    })) || [];
  }, [data?.payload]);

  const usersForMentionRef = React.useRef(getUsersForMention());

  React.useEffect(() => {
    usersForMentionRef.current = getUsersForMention();
  }, [fulfilledTimeStamp]);

  return {
    lastUpdateTimestamp: fulfilledTimeStamp,
    usersForMentionRef,
    getUsersForMention,
  };
};

const TYPING_DEBOUNCE = 4000;

type UserTimeoutType = {
  [key in 'channel' | 'thread']: {
    [channelOrThreadId: number]: {
      [userId: number]: {
        timeoutId: NodeJS.Timeout;
      };
    };
  }
};

export const useGetTypingUsers = (options: {
  type: 'channel' | 'thread';
  id?: number;
  channelId?: number;
}) => {
  const { type, id: channelOrThreadId, channelId } = options;
  const [typingUsers, setTypingUsers] = React.useState<{ [userId: number]: IUser }>({});
  const [typingTimeout, setTypingTimeout] = React.useState<number | null>(null);
  const userTimeout = React.useRef<UserTimeoutType>({} as UserTimeoutType);
  const latestMessagesListRef = React.useRef<{ userId: number; createdAt: string | Date }[]>([]);

  const user = useUser();

  const [typingUserData, setTypingUserData] = React.useState<UserTypingResponseType>();

  const isSocketConnected = useAppSelector((state) => state.main.socketData.isConnected);

  /* NOTE: This is needed for the case when a user quickly sends a message.
  There is a chance that the backend will send the typing event later than the new message event
  This should be resolved in the future by creating a temporary message ID. */
  const checkIsMessageAlreadyArrived = (userId: number) => {
    let isMessageAlreadyArrived = false;
    const currentTime = new Date().getTime();
    const MAX_DIFFERENCE_TIME = 650;

    const latestUserMessage = latestMessagesListRef.current.find(
      (message) => message.userId === userId,
    );

    if (latestUserMessage) {
      const timeDifference = currentTime - new Date(latestUserMessage.createdAt).getTime();
      isMessageAlreadyArrived = timeDifference < MAX_DIFFERENCE_TIME;
    }

    latestMessagesListRef.current =
      latestMessagesListRef.current.filter((message) => currentTime - new Date(message.createdAt).getTime() < MAX_DIFFERENCE_TIME);

    return isMessageAlreadyArrived;
  };

  const handleCheckTypingUserTimestamp = React.useCallback((userId: number) => {
    if (!channelOrThreadId) { return; }
    clearTimeout(userTimeout.current?.[type]?.[channelOrThreadId]?.[userId]?.timeoutId);
    const timeoutId = setTimeout(() => {
      setTypingUsers((prev) => {
        const updatedTypingUsers = { ...prev };
        delete updatedTypingUsers[userId];

        return updatedTypingUsers;
      });
    }, TYPING_DEBOUNCE + 1000);

    userTimeout.current = ({
      ...userTimeout.current,
      [type]: {
        ...userTimeout.current?.[type],
        [channelOrThreadId]: {
          ...userTimeout.current?.[type]?.[channelOrThreadId],
          [userId]: {
            timeoutId,
          },
        },
      },
    });
  }, [channelOrThreadId, type]);

  React.useEffect(() => {
    const unsubscribeUserTyping = chatWs.subscribeOnUserTyping((data) => {
      if (data.user.userId === user.userId) {
        return;
      }

      if (checkIsMessageAlreadyArrived(data.user.userId)) return;

      setTypingUserData(data);

      return () => {
        unsubscribeUserTyping();
      };
    });
  }, [user.userId, isSocketConnected]);

  const updateTypingUsers = React.useCallback((data: UserTypingResponseType) => {
    const isInvalidChannel = data.parentMessageId || data.channelId !== channelOrThreadId;
    const isInvalidThread = !data.parentMessageId || data.parentMessageId !== channelOrThreadId;
    const isInvalid = type === 'channel'
      ? isInvalidChannel
      : isInvalidThread;

    if (isInvalid) {
      return;
    }
    setTypingUsers((prev) => {
      const updatedTypingUsers = { ...prev };

      updatedTypingUsers[data.user.userId] = data.user;

      return updatedTypingUsers;
    });
    setTypingUserData(undefined);

    handleCheckTypingUserTimestamp(data.user.userId);
  }, [channelId, handleCheckTypingUserTimestamp, channelOrThreadId, type]);

  React.useEffect(() => {
    if (typingUserData) {
      updateTypingUsers(typingUserData);
    }
  }, [updateTypingUsers, typingUserData]);

  React.useEffect(() => {
    const unsubscribeFromNew = chatWs_v2.subscribeOnNewMessage((data) => {
      if (!data.payload.authorId) {
        return;
      }
      const isCurrentTypeData = type === 'channel'
        ? data.payload.channelId === channelOrThreadId
        : data.payload.parentMessage?.messageId === channelOrThreadId;

      if (!isCurrentTypeData) {
        return;
      }

      latestMessagesListRef.current.unshift({
        userId: data.payload.authorId,
        createdAt: data.payload.createdAt,
      });

      setTypingUsers((prev) => {
        const updatedTypingUsers = { ...prev };
        delete updatedTypingUsers[data.payload.authorId as number];

        return updatedTypingUsers;
      });
      setTypingTimeout(null);
    });
    return () => {
      unsubscribeFromNew();
    };
  }, [channelId, channelOrThreadId, type, isSocketConnected]);

  React.useEffect(() => {
    setTypingUsers({});
    setTypingUserData(undefined);
  }, [channelId, channelOrThreadId]);

  const handleTypingUsersTimeout = (isEditingMessage?: boolean) => {
    if (!channelId) {
      return;
    }

    if ((typingTimeout && typingTimeout >= Date.now() - TYPING_DEBOUNCE) || isEditingMessage) {
      return;
    }

    setTypingTimeout(Date.now());

    chatWs.userTyping({
      channelId,
      parentMessageId: type === 'thread' ? channelOrThreadId : undefined,
    });
  };

  return {
    handleTypingUsersTimeout,
    handleCheckTypingUserTimestamp,
    typingUsers,
  };
};

export const useMessageNavigate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const channel = useCurrentChannel()!;
  const channelData = useAppSelector(({ chatPageV2 }) => chatPageV2.channelDetailsObject?.[channel?.channelId as number]);
  const openedThread = useAppSelector(({ chatPageV2 }) => chatPageV2.openedThread);

  const navigateToMessage = async (message: IMessage) => {
    const targetMessageId = message.parentMessage?.messageId || message.messageId;

    const existingMessage = channelData?.messages.find((messageId) => messageId === targetMessageId);

    if (!existingMessage) {
      const searchParams: { messageId: string; parentMessageId?: string } = {
        messageId: message.messageId.toString(),
      };

      if (message.parentMessage?.messageId) {
        searchParams.parentMessageId = message.parentMessage?.messageId.toString();
      }

      navigate({
        pathname: `${ROUTES.chat.createPath(message.channelId)}`,
        search: `?${createSearchParams(searchParams)}`,
      });

      return;
    }

    if (message.parentMessage?.messageId && message.channelId === channel?.channelId) {
      const isThreadOpen = openedThread.channelId === channel?.channelId &&
        openedThread.parentMessageId === message.parentMessage?.messageId;

      if (!isThreadOpen) {
        closeProfileDrawer();
        await dispatch(chatThunksV2.openThread({
          channelId: channel.channelId as number,
          parentMessageId: message.parentMessage?.messageId,
          messageId: message.messageId,
        }));
      } else {
        chatScrollController.scrollToMessage({
          isThread: true,
          isHighlightMessage: true,
          smooth: true,
          messageId: message.messageId,
        });

        return;
      }
    }

    chatScrollController.scrollToMessage({ messageId: targetMessageId, smooth: true, isHighlightMessage: true });
  };

  return { navigateToMessage };
};

export const useOpenDm = (onSuccess?: () => void) => {
  const navigate = useNavigate();
  const [isDmOpenning, setIsDmOpenning] = React.useState(false);

  const openDm = React.useCallback(async (userId: number, params?: { clearLoaderOnEnd?: boolean }) => {
    try {
      setIsDmOpenning(true);
      const response = await channelApi_V2.getDmOrGroupChannel({ userIds: [userId] });

      navigate(ROUTES.chat.createPath(response.data.payload.channelId));
    } catch (err) {
      setIsDmOpenning(false);

      console.error('Failed to open DM:', err);

      toast.error('Не удалось открыть диалог');
    } finally {
      if (params?.clearLoaderOnEnd) {
        setIsDmOpenning(false);
      }
      onSuccess?.();
    }
  }, [navigate, onSuccess]);

  return {
    openDm,
    isDmOpenning,
    loaderNode: isDmOpenning ? <Loader isFixed blockPoinerEvents withBackdrop /> : null,
  };
};

export const useMyChannelMembership = () => {
  const pathData = useChatPathData();
  const channelDetails = useAppSelector(({ chatPageV2 }) => (pathData.channelId ? chatPageV2.channelDetailsObject[pathData.channelId] : null));
  const channelData = useAppSelector(({ chatPageV2 }) => (pathData.channelId ? chatPageV2.channelsObject[pathData.channelId] : null));
  const user = useUser();

  const channelMembershipData = React.useMemo(() => {
    const meInChannel = channelDetails?.usersToChannel.find(({ userId }) => userId === user.userId);

    return {
      lastViewedMessageTime: meInChannel?.lastViewedMessageTime || null,
      isMeInChannel: Boolean(meInChannel),
      isMeOwner: channelData?.ownerId === user.userId,
    };
  }, [channelDetails, channelData, user.userId]);

  return channelMembershipData;
};

export const useChannelAccess = () => {
  const user = useUser();
  const { isMain } = useMainChannel();
  const { isMeInChannel, isMeOwner } = useMyChannelMembership();
  const currentChannel = useCurrentChannel();

  /** This way it looks bigger, but it's easier to maintain and understand */
  const channelAccessData = React.useMemo<{
    canEditChannelInfo: boolean;
    canEditChannelMembers: boolean;
    canEditChannelsSettings: boolean;
    canAddUserToChannel: boolean;
    canDeleteAnyMessage: boolean;
    canLeaveChannel: boolean;
    canChangeOwner: boolean;
  }>(() => {
    if (!currentChannel || (currentChannel.isPrivate && !isMeInChannel)) {
      return {
        canEditChannelInfo: false,
        canEditChannelMembers: false,
        canEditChannelsSettings: false,
        canAddUserToChannel: false,
        canDeleteAnyMessage: false,
        canLeaveChannel: false,
        canChangeOwner: false,
      };
    }

    const { isPrivate, isArchived } = currentChannel;
    const havePermissionToEditAnyChannelMembers = validateUserPermissions(user, [UserPermissionsENUM.chat__editAnyChannelMembers]);
    const canChangeOwner = isMeOwner || havePermissionToEditAnyChannelMembers;

    if (isArchived) {
      return {
        canEditChannelInfo: false,
        canEditChannelMembers: false,
        canEditChannelsSettings: isMeOwner || ((!isPrivate || isMeInChannel) && validateUserPermissions(user, [UserPermissionsENUM.chat__manageAllArchivedChannels])), // eslint-disable-line max-len
        canAddUserToChannel: false,
        canDeleteAnyMessage: false,
        canLeaveChannel: false,
        canChangeOwner,
      };
    }

    const havePermissionToDeleteAnyMessage = validateUserPermissions(user, [UserPermissionsENUM.chat__deleteAnyMessage]);

    if (isMeOwner) {
      return {
        canEditChannelInfo: true,
        canEditChannelMembers: true,
        canEditChannelsSettings: true,
        canAddUserToChannel: true,
        canDeleteAnyMessage: havePermissionToDeleteAnyMessage,
        canLeaveChannel: !isMain,
        canChangeOwner,
      };
    }

    const havePermissionToEditAnyChannelInfo = validateUserPermissions(user, [UserPermissionsENUM.chat__editAnyChannelInfo]);
    const havePermissionToAddUserToAnyChannel = validateUserPermissions(user, [UserPermissionsENUM.chat__addUserToAnyChannel]);
    const havePermissionToEditAllChannelsSettings = validateUserPermissions(user, [UserPermissionsENUM.chat__editAllChannelsSettings]);

    if (isPrivate) {
      return {
        canEditChannelInfo: isMeInChannel && havePermissionToEditAnyChannelInfo,
        canEditChannelMembers: isMeInChannel && havePermissionToEditAnyChannelMembers,
        canEditChannelsSettings: isMeInChannel && havePermissionToAddUserToAnyChannel,
        canAddUserToChannel: isMeInChannel && havePermissionToEditAllChannelsSettings,
        canDeleteAnyMessage: isMeInChannel && havePermissionToDeleteAnyMessage,
        canLeaveChannel: isMeInChannel && !isMain,
        canChangeOwner,
      };
    }

    const canEditChannelInfo = havePermissionToEditAnyChannelInfo;
    const canEditChannelMembers = havePermissionToEditAnyChannelMembers;
    const canAddUserToChannel = isMeInChannel || havePermissionToAddUserToAnyChannel;
    const canEditChannelsSettings = havePermissionToEditAllChannelsSettings;
    const canDeleteAnyMessage = havePermissionToDeleteAnyMessage;
    const canLeaveChannel = isMeInChannel && !isMain;

    return {
      canEditChannelInfo,
      canEditChannelMembers,
      canEditChannelsSettings,
      canAddUserToChannel,
      canDeleteAnyMessage,
      canLeaveChannel,
      canChangeOwner,
    };
  }, [user, isMeInChannel, isMeOwner, currentChannel, isMain]);

  return channelAccessData;
};
