import http, { type ResponseType } from 'src/api/http';
import type { IChannel, IMessage, UserToThreadType } from 'src/types';

const createDmChannel = (data: {
  userId: number;
}) => {
  return http.post<ResponseType<IChannel>>('/channels/create-dm', { participantId: data.userId });
};

const createGroupChannel = (data: {
  usersIds: number[];
}) => {
  return http.post<ResponseType<IChannel>>('/channels/create-group', { participantIds: data.usersIds });
};

const getDmOrGroupChannel = (data: {
  userIds: number[];
}) => {
  if (data.userIds.length === 1) {
    return createDmChannel({ userId: data.userIds[0] });
  }
  return createGroupChannel({ usersIds: data.userIds });
};

const checkExistingDm = (
  params: {
    userIds: string;
  },
) => {
  return http.get<ResponseType<number>>('/channels/check-existing-dm', { params });
};

type ThreadsResponseMetaType = {
  hasMore: boolean;
  unreadMessages: Record<string, number>;
  threadUsers: Record<string, UserToThreadType[]>;
};

const getThreadMessages = (params?: {
  messagesIds?: number[];
  excludeMessagesIds?: number[];
  downAnchor?: string | Date;
  signal?: AbortSignal;
}) => {
  const body: Record<string, string> = {};
  if (params?.excludeMessagesIds && params.excludeMessagesIds.length) {
    body.excludeMessagesIds = params.excludeMessagesIds.join(',');
  }

  if (params?.messagesIds) {
    body.messagesIds = params.messagesIds.join(',');
  }

  return http.post<ResponseType<IMessage[], ThreadsResponseMetaType>>('/channels/threads-channel', body, { signal: params?.signal });
};

const closeDmChannel = (data: {
  channelId: number;
}) => {
  return http.patch<ResponseType<IChannel>>(`/channels/${data.channelId}`);
};

export default {
  getDmOrGroupChannel,
  checkExistingDm,
  getThreadMessages,
  closeDmChannel,
};
