import { createAsyncThunk } from '@reduxjs/toolkit';
import type { DefaultRootState } from 'react-redux';
import type { AxiosError } from 'axios';
import axios, { CanceledError } from 'axios';
import _uniqueId from 'lodash/uniqueId';
import _uniq from 'lodash/uniq';

import chatWs from 'src/api/ws/chatWs';
import type { IChannel, IMessage, IMessageText, MessageType } from 'src/types';
import chatApi from 'src/api/chatApi';
import * as chatStoreHelpers from './helpers';
import type { AppStateType, AppThunkConfigType } from 'src/store';
// VVV Remove later VVV
import socket from 'src/api/ws/socket';
import socketConstants from 'src/api/socket/constants';
import type { IMedia, MediaItemMimeTypeENUM, MediaItemType, MessageLinkPreviewType } from 'src/types/chatTypes';
import { MessageTypeENUM } from 'src/types/chatTypes';
import { toast } from 'react-toastify';
import { t } from 'src/utils/getTranslation';
import chatSliceV2, { chatSliceV2Actions } from './chatSliceV2';
import chatWs_v2 from '../api/chatWs_v2';
import chatScrollController from '../utils/chatScrollController';
import { MESSAGES_LIST_LIMIT } from '../constants';
import messageApi_V2 from '../api/messageApi_V2';
import mediaApi from 'src/api/mediaApi';
import errorCodes from 'src/utils/errorCodes';
import uploadMediaItemEvent from '../uploadMediaItemsEvent';
import { errorAllPendtingMessages, getMentionsFromText, getPendingMessages, setPendingMessages } from './chatSliceUtils';
import type { ValidationErrorType } from 'src/utils/handleValidationError';
import { checkIsValidationError } from 'src/utils/handleValidationError';
import { chatDrafts } from '../Chat.utils';
import { sortByStrings } from 'src/utils/stringFormattingUtils';
import checkIsSupportedImage from '../utils/checkIsImage';
import channelApi_V2 from '../api/channelApi_V2';
import type { MediaStoreInnerItemKeyType } from './types';
import helpers from 'src/utils/helpers';

const sortChannels = (a: IChannel, b: IChannel) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  return sortByStrings(nameA, nameB);
};

const getMyChannels = createAsyncThunk('chatPage-v2/getMyChannels', async () => {
  const { allChannels, channelsMeta } = await chatWs.getMyChannels();

  const channelsObject: Record<string, IChannel> = {};
  const channels: number[] = [];
  const dmChannels: number[] = [];
  const archivedChannels: number[] = [];

  allChannels.channels.sort(sortChannels).forEach((channel) => {
    channelsObject[channel.channelId] = channel;
    channels.push(channel.channelId);
  });

  allChannels.dmChannels
    .forEach((channel) => {
      channelsObject[channel.channelId] = channel;
      dmChannels.push(channel.channelId);
    });

  allChannels.archivedChannels.sort(sortChannels).forEach((channel) => {
    channelsObject[channel.channelId] = channel;
    archivedChannels.push(channel.channelId);
  });

  return {
    channelsObject,
    channels,
    dmChannels,
    archivedChannels,
    channelsMeta,
  };
});

const markMessageAsRead = createAsyncThunk('chatPage-v2/markMessageAsRead', async (
  data: {
    message: MessageType;
    channelId: number;
    parentMessageId?: number;
  },
  { getState },
) => {
  const store = getState() as DefaultRootState;

  const { channelId } = data;
  const lastViewedMessageTime = data.message.createdAt as string;
  const response = await chatWs.readMessage({
    channelId,
    lastViewedMessageTime,
    parentMessageId: data.parentMessageId,
  });

  return {
    channelId,
    parentMessageId: data.parentMessageId,
    newMeta: response.newMeta,
    lastViewedMessageTime,
    userId: store.main.user!.userId,
  };
});

const markMessageAsUnread = createAsyncThunk('chatPage-v2/markMessageAsUnRead', async (
  data: {
    message: MessageType;
    channelId: number;
    parentMessageId?: number;
  },
  { getState },
) => {
  const store = getState() as DefaultRootState;
  const { channelId } = data;
  const lastViewedMessageTime = data.message.createdAt as string;
  const response = await chatWs.markAsUnreadMessage({
    channelId,
    lastViewedMessageTime,
    parentMessageId: data.parentMessageId,
  });

  return {
    channelId,
    parentMessageId: data.parentMessageId,
    newMeta: response.newMeta,
    lastViewedMessageTime: response.lastViewedMessageTime,
    userId: store.main.user!.userId,
    // For the future usage, when we will have thread unread logic;
    userThreadId: response.userThreadId,
  };
});

const getChannelData = createAsyncThunk('chatPage-v2/getChannelData', async (
  data: {
    channelId: number;
    scrollTargetMessageId?: number;
    toBottom?: boolean;
    shouldRefresh?: boolean;
  },
  { getState, dispatch, signal },
) => {
  const {
    chatPageV2: {
      channelsMeta,
      channelDetailsObject,
      channelsObject,
    },
    main: {
      user,
    } } = getState() as AppStateType;
  const channelDetails = channelDetailsObject?.[data.channelId];
  const unreadMessagesCount = channelsMeta[data.channelId]?.unreadMessagesCount || 0;
  chatScrollController.setChannelId(data.channelId);
  dispatch(chatSliceV2Actions.clearFirstUnreadMessage({ channelId: data.channelId }));
  const shouldChannelScroll = channelDetails?.hasMoreDown === false && !data.shouldRefresh && !data.scrollTargetMessageId;

  if (shouldChannelScroll && !channelDetails.isPartiallyLoaded) {
    const { firstUnreadId, firstUnreadMessage } = chatStoreHelpers.getFirstUnreadMessage({
      channelDetails,
      currentUserId: user!.userId,
      userToChannels: channelsObject[data.channelId].userToChannels,
    });

    if (firstUnreadId) {
      setTimeout(() => {
        chatScrollController.scrollToFirstUnread();
      }, 100);
    }

    return {
      channel: channelsObject[data.channelId],
      firstUnreadMessageId: firstUnreadId,
      userId: user!.userId,
      lastReadMessageTimestamp: firstUnreadMessage?.createdAt,
    };
  }

  const [
    { data: { payload: { pinnedMessagesIds, ...channel } } },
    { channelMessages, hasMoreUp, hasMoreDown, firstUnreadMessageId, meta },
  ] = await Promise.all([
    chatApi.getChannelWithPinned(data.channelId, signal),
    chatStoreHelpers.getChannelMessages({
      channelId: data.channelId,
      toBottom: data.toBottom,
      scrollTargetMessageId: data.scrollTargetMessageId,
      unreadMessagesCount: data.toBottom ? undefined : unreadMessagesCount,
    }),
  ]);

  if (data.scrollTargetMessageId) {
    setTimeout(() => {
      chatScrollController.scrollToMessage({ messageId: data.scrollTargetMessageId!, isHighlightMessage: true });
      // NOTE: This delay is necessary to resolve a conflict with the thread scrolling
    }, 100);
  } else if (meta.firstUnreadMessageId) {
    chatScrollController.scrollToFirstUnread();
  }

  const messagesObject = helpers.arrayToObject(channelMessages, 'messageId');

  const messages = channelMessages.map(({ messageId }) => messageId);

  const {
    users,
    usersToChannel,
  } = chatStoreHelpers.extractUsersData({ userToChannelsOrigin: channel.userToChannels });

  return {
    channel,
    messages,
    messagesObject,
    usersToChannel,
    users,
    userId: user!.userId,
    hasMoreUp,
    hasMoreDown,
    firstUnreadMessageId,
    pinnedMessagesIds,
  };
});

const getPinnedChannelMessages = createAsyncThunk('chatPage-v2/getPinnedChannelMessages', async (
  data: {
    channelId: number;
  },
) => {
  const response = await chatApi.getChannelPinnedMessages(data.channelId);
  const messagesObject = helpers.arrayToObject(response.data.payload, 'messageId');

  return {
    messagesObject,
    pinnedMessages: response.data.payload,
  };
});

const loadMoreMessages = createAsyncThunk('chatPage-v2/loadMoreMessages', async (
  data: {
    channelId: number;
    anchorType: 'top' | 'bottom';
  },
  { getState },
) => {
  const { chatPageV2: { channelDetailsObject } } = getState() as AppStateType;

  const channelDetails = channelDetailsObject[data.channelId]!;
  const messages = channelDetails.messages;

  const anchorMessageId = data.anchorType === 'top' ? messages[0]! : messages[messages.length - 1];
  const anchorMessage = channelDetails.channelMessagesObject[anchorMessageId];
  const anchorData = anchorMessage?.createdAt;

  const response = await chatWs_v2.getChannelMesages({
    channelId: data.channelId,
    limit: MESSAGES_LIST_LIMIT,
    downAnchor: data.anchorType === 'bottom' ? anchorData : undefined,
    upAnchor: data.anchorType === 'top' ? anchorData : undefined,
  });

  return {
    channelId: data.channelId,
    messages: response.payload,
    hasMoreUp: data.anchorType === 'top' ? response.meta.hasMoreUp! : channelDetails.hasMoreUp,
    hasMoreDown: data.anchorType === 'bottom' ? response.meta.hasMoreDown! : channelDetails.hasMoreDown,
    direction: data.anchorType,
  };
});

let counter = 0;
const sendMessage = createAsyncThunk('chatPage-v2/sendMessage', async (
  data: {
    channelId: number;
    messageText: string;
    temporaryMessage?: IMessage;
    referencedMessageId?: number | null;
    mediaItems?: MediaItemType[];
    parentMessageId?: number | null;
    startingDmOrGroupMessage?: boolean;
    mediaItemsChannelId?: number;
    inputType?: MediaStoreInnerItemKeyType;
    previewLinks?: string[];
  },
  { getState, dispatch },
) => {
  const { channelId, previewLinks } = data;
  const store = getState() as AppStateType;
  const userId = store.main.user!.userId;
  const inputType = data.inputType ?? (data.parentMessageId ? 'threadInput' : 'channelInput');
  const nowDate = new Date();
  let temporaryMessage = {} as IMessage;

  try {
    if (!data.startingDmOrGroupMessage) {
      if (data.mediaItems?.length) {
        data.mediaItems.forEach((mediaItem) => {
          dispatch(chatSliceV2.actions.updateMediaItemData({
            channelId: data.mediaItemsChannelId ?? data.channelId,
            fileData: {
              isSent: true,
            },
            fileId: mediaItem.id,
            inputType,
          }));
        });
      }

      const formattedMediaItems = data.mediaItems?.map((item) => ({
        ...item.mediaItem,
        link: item.mediaItem?.link ?? item.src,
        mimeType: item.mediaItem?.mimeType ?? item.fileType,
        name: item.mediaItem?.name ?? item.file?.name,
        mediaItemId: item.mediaItem?.mediaItemId ?? --counter,
        file: item.file,
        createdAt: nowDate,
        isTemporary: !item.mediaItem?.mediaItemId,
      })) as IMedia[];

      const channelDetails = store.chatPageV2.channelDetailsObject[channelId];

      if (!channelDetails && typeof data.mediaItemsChannelId !== 'number') {
        throw new Error(`Channel data for channel "${data.channelId}" not found`);
      }

      const parentMessage = data.parentMessageId ? channelDetails.channelMessagesObject[data.parentMessageId] : null;
      let referencedMessage = null as IMessage | null;
      if (parentMessage && data.referencedMessageId) {
        referencedMessage = store.chatPageV2.threads[parentMessage.messageId].messagesObject[data.referencedMessageId];
      } else if (data.referencedMessageId) {
        referencedMessage = channelDetails.channelMessagesObject[data.referencedMessageId];
      }

      temporaryMessage = data.temporaryMessage
        ? {
          ...data.temporaryMessage,
          isSending: true,
          isError: false,
        }
        : {
          messageId: -Date.now(),
          messageText: [{
            text: data.messageText,
            createdAt: nowDate,
            updatedAt: nowDate,
            messageTextId: Date.now(),
          }],
          media: formattedMediaItems,
          parentMessage,
          referencedMessage,
          author: store.main.user!,
          authorId: store.main.user?.userId,
          createdAt: nowDate,
          updatedAt: nowDate,
          hasBeenRead: false,
          isAnswer: false,
          isEdited: false,
          isSending: true,
          isError: false,
          isPinned: false,
          isViewed: false,
          type: MessageTypeENUM.message,
          reactions: [],
          channelId,
        };

      if (channelDetails && !channelDetails.hasMoreDown) {
        dispatch(chatSliceV2.actions.handleNewSendingMessage({
          channelId,
          parentMessageId: data.parentMessageId,
          message: temporaryMessage,
          userId,
        }));
      }
    }

    const mentions = getMentionsFromText(data.messageText);
    const waitingFilesIds = new Set<string>();
    const mediaItemIds = new Set<number>();

    if (data.mediaItems?.length && !data.mediaItems?.every((mediaItem) => mediaItem.mediaItem?.mediaItemId)) {
      data.mediaItems?.forEach((mediaItem) => {
        if (mediaItem.mediaItem) {
          waitingFilesIds.add(mediaItem.id);
          mediaItemIds.add(mediaItem.mediaItem.mediaItemId);
        }
      });
      await new Promise<void>((res) => {
        uploadMediaItemEvent.subscribe((mediaItemsData) => {
          Object.entries(mediaItemsData).forEach(([key, value]) => {
            if (data.mediaItems?.some((item) => item.id === key)) {
              waitingFilesIds.add(key);
              if (!value.isError) {
                mediaItemIds.add(value.mediaItemId);
              }
            }
          });

          if (waitingFilesIds.size === data.mediaItems?.length) {
            res();
          }
        });
      });
    } else {
      data.mediaItems?.forEach((item) => {
        mediaItemIds.add(item.mediaItem?.mediaItemId as number);
      });
    }

    const response = await chatWs_v2.sendMessage({
      channelId,
      mediaItemsIds: Array.from(mediaItemIds),
      text: data.messageText,
      parentMessageId: data.parentMessageId,
      referenceMessageId: data.referencedMessageId,
      mentionUsersIds: mentions.add,
      previewLinks,
    });

    return {
      message: response.payload,
      channelId,
      parentMessageId: data.parentMessageId,
      temporaryMessageId: temporaryMessage?.messageId,
      isFailed: false,
      userId,
    };
  } catch (err) {
    console.error('Failed to send a message', err);

    return {
      message: {
        ...temporaryMessage,
        isError: true,
        isSending: false,
      },
      channelId,
      parentMessageId: data.parentMessageId,
      temporaryMessageId: temporaryMessage.messageId,
      isFailed: true,
      userId,
    };
  }
});

const deleteMessage = createAsyncThunk('chatPage-v2/deleteMessage', async (
  data: {
    channelId: number;
    messageId: number;
    parentMessageId?: number;
  },
  { dispatch },
) => {
  let deletedAt: Date | undefined = !data.parentMessageId ? new Date() : undefined;
  try {
    dispatch(chatSliceV2.actions.updateMessage({
      ...data,
      messageData: {
        isSending: true,
        deletedAt,
      },
    }));

    await socket.emit(socketConstants.DELETE_MESSAGE, data);

    // dispatch(chatSliceV2.actions.deleteMessage(data));
  } catch (err) {
    console.error('Failed to delete a message:', err);
    deletedAt = undefined;

    toast.error(t('errors:server.internal.unexpected'));
  } finally {
    dispatch(chatSliceV2.actions.updateMessage({
      ...data,
      messageData: { isSending: false, deletedAt },
    }));
  }
});

const toggleMessagePinStatus = createAsyncThunk('chatPage-v2/toggleMessagePinStatus', async (
  data: {
    isPinned: boolean;
    message: IMessage;
    channelId: number;
    parentMessageId?: number;
  },
  { dispatch, signal },
) => {
  const oldPinnedStatus = !data.isPinned;
  try {
    dispatch(chatSliceV2.actions.updateMessage({
      channelId: data.channelId,
      messageId: data.message.messageId,
      parentMessageId: data.parentMessageId,
      messageData: { isPinned: data.isPinned },
    }));

    // dispatch(chatSliceV2.actions.handlePinnedMessage({
    //   isAdded: data.isPinned,
    //   messageId: data.messageId,
    //   channelId: data.channelId,
    // }));

    await chatApi.togglePinMessage(
      data.message.messageId,
      data.channelId,
      signal,
    );
  } catch (err) {
    if (err instanceof CanceledError) {
      return;
    }

    console.error('Failed to toggle message pin status:', err);

    toast.error(t('errors:server.internal.unexpected'));

    dispatch(chatSliceV2.actions.updateMessage({
      channelId: data.channelId,
      messageId: data.message.messageId,
      messageData: { isPinned: oldPinnedStatus },
    }));
  }
});

const editMessage = createAsyncThunk<
  {
    channelId: number;
    message: IMessage;
  },
  {
    messageId: number;
    channelId: number;
    text: string;
    parentMessageId?: number;
    messageLinkPreviews?: MessageLinkPreviewType[];
    previewLinks?: string[];
  },
  AppThunkConfigType
>('chatPage-v2/editMessage', async (
  data,
  { getState, dispatch },
) => {
  const { chatPageV2 } = getState();
  let oldMessage = null as IMessage | null;
  if (data.parentMessageId) {
    oldMessage = chatPageV2.threads[data.parentMessageId].messagesObject[data.messageId];
  } else {
    oldMessage = chatPageV2.channelDetailsObject[data.channelId].channelMessagesObject[data.messageId];
  }
  oldMessage = structuredClone(oldMessage) as IMessage;

  try {
    const newMessageText: IMessageText[] = [];
    if (oldMessage.messageText?.length) {
      newMessageText[0] = {
        ...oldMessage.messageText[0],
        text: data.text,
      };
    } else {
      newMessageText[0] = {
        text: data.text,
        createdAt: new Date(),
        messageTextId: Date.now(),
        updatedAt: new Date(),
      };
    }
    dispatch(chatSliceV2.actions.updateMessage({
      ...data,
      messageData: {
        ...oldMessage,
        messageText: newMessageText,
        isEdited: true,
        userEditedAt: new Date(),
        messageLinkPreviews: data.messageLinkPreviews,
      },
    }));

    const oldText = oldMessage.messageText?.[0]?.text || '';
    const mentions = getMentionsFromText(data.text, oldText);

    await messageApi_V2.edit(data.messageId, {
      messageText: data.text,
      addUserMentionsIds: mentions.add,
      removeUserMentionsIds: mentions.remove,
      previewLinks: data.previewLinks,
    });

    return {
      channelId: data.channelId,
      message: oldMessage,
    };
  } catch (err) {
    console.error('Failed to update a message:', err);
    toast.error('Не удалось обновить сообщение');

    dispatch(chatSliceV2.actions.updateMessage({
      ...data,
      messageData: oldMessage,
    }));

    throw err;
  }
});

type UploadFilesParamsType = {
  files: MediaItemType[];
  inputType: MediaStoreInnerItemKeyType;
  channelId: number;
  messageId: number | 'null';
  onUpload?: (messageData: MessageType) => void;
  parentMessageId?: number;
};

const uploadFiles = createAsyncThunk<void, UploadFilesParamsType,
  AppThunkConfigType>(
    'chat/uploadFiles',
    async (params, { dispatch, signal, getState }) => {
      const uploadImage = async (media: MediaItemType) => {
        if (!media.file) {
          return;
        }
        const data = {
          name: media.file.name,
          file: media.src,
          isPrivate: true,
        };

        const config = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            handleLoadingInfoChange({
              fileId: media.id,
              uploaded: progressEvent.loaded,
              total: progressEvent.total,
            });
          },
          signal,
        };
        const { data: response } = await mediaApi.uploadImage(data, config);

        updateMediaItemData(media.id, response.payload);

        return response.payload;
      };

      const uploadFile = async (media: MediaItemType) => {
        if (media.errorMessage || !media.file) {
          return;
        }
        const fileType = media.file.type || 'text/plain';
        const mimeTypeBody = fileType;
        const presignedUrlParams = { fileName: _uniqueId(), mimeType: mimeTypeBody };
        const link = await mediaApi.getPresignedLink(presignedUrlParams);
        const linkArr = link.data.payload.split('/');
        const companyId = linkArr[3];
        const name = linkArr[4].split('?')[0];
        const key = `${companyId}/${name}`;

        const config = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            handleLoadingInfoChange(
              {
                fileId: media.id,
                uploaded: progressEvent.loaded,
                total: progressEvent.total,
              },
            );
          },
          headers: {
            'Content-Type': fileType,
          },
          signal,
        };
        const data = media.file;
        await axios.put(link.data.payload, data, config);

        const mediaItemData = {
          mimeType: fileType,
          name: media.file.name,
          key,
          isPrivate: true,
        };
        const { data: response } = await mediaApi.saveMediaItem(mediaItemData, { signal });

        updateMediaItemData(media.id, response.payload);

        return response.payload;
      };

      const handleLoadingInfoChange = (options: {
        fileId: string;
        uploaded: number;
        total: number;
      }) => {
        dispatch(chatSliceV2.actions.updateMediaItemData({
          channelId: params.channelId,
          fileData: {
            progress: Math.round((options.uploaded * 100) / options.total),
            isLoading: options.uploaded !== options.total,
            uploadedData: options.uploaded,
            totalSize: options.total,
          },
          fileId: options.fileId,
          inputType: params.inputType,
        }));
      };

      const updateMediaItemData = (fileId: string, mediaItem: IMedia) => {
        dispatch(chatSliceV2.actions.updateMediaItemData({
          channelId: params.channelId,
          fileData: {
            mediaItem,
          },
          fileId,
          inputType: params.inputType,
        }));
      };

      const mediaFiles = Object.values(params.files);

      try {
        const mediaFilesWithMediaItems: (MediaItemType & { isError?: boolean })[] = await Promise.all(mediaFiles.map(async (mediaFile) => {
          try {
            const isSupportedImage = checkIsSupportedImage(mediaFile.file!.type);

            const uploadFunction = isSupportedImage
              ? uploadImage
              : uploadFile;

            const { main: { user } } = getState();
            const currentUserId = user?.userId;
            const mediaItem = await uploadFunction(mediaFile);

            if (mediaItem) {
              const formattedMediaFile = {
                id: mediaFile.id,
                isLoading: false,
                progress: 100,
                src: mediaItem.link || '',
                mediaItem,
                fileType: mediaFile.file?.type as MediaItemMimeTypeENUM,
                totalSize: mediaFile.file?.size as number,
                file: null,
                fileExtension: mediaItem.name.split('.').pop(),
              };
              // eslint-disable-next-line max-len
              const currentMediaFile = getState().chatPageV2.mediaItems?.[params.channelId]?.[params.inputType].files.find((file) => file.id === mediaFile.id);
              if (currentMediaFile && !currentMediaFile?.isSent) {
                if (currentUserId) {
                  if (!params.parentMessageId) {
                    const channelChatDrafts = chatDrafts.channel.get(params.channelId, currentUserId);

                    chatDrafts.channel.setImmediately(params.channelId, {
                      text: channelChatDrafts.text,
                      mediaItems: [...channelChatDrafts.mediaItems || [], formattedMediaFile],
                    }, currentUserId);
                  } else {
                    const threadChatDrafts = chatDrafts.thread.get(params.channelId, params.parentMessageId!, user.userId);
                    // eslint-disable-next-line max-len
                    chatDrafts.thread.setImmediately(
                      params.channelId,
                      params.parentMessageId!,
                      {
                        text: threadChatDrafts.text,
                        mediaItems: [...threadChatDrafts.mediaItems || [], formattedMediaFile],
                      },
                      currentUserId,
                    );
                  }
                }
              } else {
                dispatch(chatSliceV2Actions.deleteMediaItem({
                  channelId: params.channelId,
                  inputType: params.inputType,
                }));
              }
            }
            return {
              ...mediaFile,
              mediaItem,
            };
          } catch (error) {
            if (checkIsValidationError(error) && (error as AxiosError<ValidationErrorType>).response?.data?.data.some((item) => item.key === 'mimeType')) {
              dispatch(chatSliceV2.actions.updateMediaItemData({
                channelId: params.channelId,
                fileData: {
                  errorMessage: t('errors:validation.file.unsupported'),
                },
                fileId: mediaFile.id,
                inputType: params.inputType,
              }));
            }
            return {
              ...mediaFile,
              isError: true,
            };
          }
        }));

        const sortedMediaFilesWithMediaItems = mediaFiles.map((mediaFile, index) => {
          // eslint-disable-next-line max-len
          const currentMediaFile = getState().chatPageV2.mediaItems?.[params.channelId]?.[params.messageId as number]?.files.find((file) => file.id === mediaFile.id);
          if (currentMediaFile && currentMediaFile.isSent && !mediaFilesWithMediaItems[index]?.isError) {
            dispatch(chatSliceV2Actions.deleteMediaItem({
              channelId: params.channelId,
              inputType: params.inputType,
            }));
          }
          return mediaFilesWithMediaItems[index];
        });

        const formattedFiles = sortedMediaFilesWithMediaItems.reduce((acc, mediaFile) => {
          acc[mediaFile?.id] = {
            isError: Boolean(mediaFile?.isError),
            mediaItemId: mediaFile?.mediaItem?.mediaItemId as number,
          };
          return acc;
        }, {} as Record<string, { isError?: boolean; mediaItemId: number }>);
        uploadMediaItemEvent.dispatch(formattedFiles);
      } catch (err) {
        toast.error(t(errorCodes.chat.loadingFilesError));
      }
    },
  );

const openThread = createAsyncThunk('chatPage-v2/openThread', async (
  data: {
    channelId: number;
    parentMessageId: number;
    messageId?: number;
    shouldRefresh?: boolean;
  },
  { getState, dispatch },
) => {
  const { chatPageV2, main: { user } } = getState() as AppStateType;

  dispatch(chatSliceV2Actions.setOpenedaThreadData({
    ...data,
    isLoading: true,
  }));

  try {
    const currentData = chatPageV2.threads[data.parentMessageId];

    let threadData = structuredClone(currentData);

    if (!threadData) {
      const storedPendingMessages = getPendingMessages('thread', data.parentMessageId, user!.userId);
      const pendingMessages = errorAllPendtingMessages(storedPendingMessages);
      setPendingMessages('thread', data.parentMessageId, user!.userId, pendingMessages);

      threadData = {
        parentMessageId: data.parentMessageId,
        channelId: data.channelId,
        pendingMessages,
        messages: [],
        messagesObject: {},
        userToThreads: [],
        firstUnreadMessageId: null,
      };
    }

    const parentMessage = chatPageV2.channelDetailsObject[data.channelId].channelMessagesObject[data.parentMessageId];
    const isFetchThreadData = parentMessage?.childMessages?.length || data.shouldRefresh;
    if (isFetchThreadData) {
      const response = await chatWs_v2.getThreadMesages({ parentMessageId: data.parentMessageId });
      threadData.userToThreads = response.meta.threadUsers;

      threadData.messagesObject = response.payload.reduce<Record<string, IMessage>>((acc, message) => {
        acc[message.messageId] = message;
        threadData.messages.push(message.messageId);
        return acc;
      }, {});

      if (!response.meta.firstUnreadMessageId) {
        threadData.firstUnreadMessageId = null;
      }
      if (response.meta.firstUnreadMessageId && !data.messageId) {
        threadData.firstUnreadMessageId = response.meta.firstUnreadMessageId;

        chatScrollController.scrollToFirstUnread(true);
      } else if (response.payload.length) {
        chatScrollController.scrollToMessage({
          messageId: data.messageId || response.payload[response.payload.length - 1].messageId,
          isHighlightMessage: Boolean(data.messageId),
        });
      }
    }

    threadData.messages = _uniq(threadData.messages);

    return threadData;
  } catch (err) {
    console.error('Failed to load thread data:', err);

    toast.error(t('errors:server.internal.unexpected'));

    throw err;
  } finally {
    dispatch(chatSliceV2Actions.setOpenedaThreadData({
      ...data,
      isLoading: false,
    }));
  }
});

const pendingReactions = {} as {
  [messageId: number]: number; // TimeoutId as value
};

type ToggleReactionDataType = {
  channelId: number;
  parentMessageId: number | null;
  messageId: number;
  emoji: string;
  shortcode: string;
  modificator: string | null;
  actionType: 'add' | 'remove';
};
const toggleReaction = createAsyncThunk<void, ToggleReactionDataType, AppThunkConfigType>('chatPage-v2/toggleReaction', async (
  data,
  { dispatch, getState },
) => {
  clearTimeout(pendingReactions[data.messageId]);

  const user = getState().main.user!;

  const reaction = {
    shortcode: data.shortcode,
    reactions: [{
      emoji: data.emoji,
      modificator: data.modificator,
      user: {
        userId: user.userId,
        firstName: user.firstName!,
        lastName: user.lastName!,
        fullName: user.fullName!,
        workingFrom: user.workingFrom,
      },
    }],
  };

  const actionData = {
    actionType: data.actionType,
    channelId: data.channelId,
    messageId: data.messageId,
    parentMessageId: data.parentMessageId,
    reaction,
  };

  dispatch(chatSliceV2Actions.toggleReaction(actionData));

  pendingReactions[data.messageId] = +setTimeout(async () => {
    try {
      await chatWs_v2.toggleReaction({
        messageId: data.messageId,
        emoji: data.emoji,
        shortcode: data.shortcode,
        modificator: data.modificator,
      });
    } catch (err) {
      console.error('Failed to toggle reaction:', err);
      toast.error(t('errors:server.internal.unexpected'));

      dispatch(chatSliceV2Actions.toggleReaction({
        ...actionData,
        actionType: data.actionType === 'add' ? 'remove' : 'add',
      }));
    }
  }, 500);
});

const getThreadChannelMessages = createAsyncThunk(
  'chatPage-v2/getThreadChannelMessages',
  async (params: {
    messagesIds?: number[];
    extraResults?: boolean;
    position?: 'top' | 'bottom';
  }, { getState, signal }) => {
    const store = getState() as AppStateType;
    const excludeMessagesIds = store.chatPageV2.threadsChannelMessages.map((m) => m.messageId);
    const newMessagesIds = store.chatPageV2.newThreadsMessagesInQueue;

    const response = await channelApi_V2.getThreadMessages({
      excludeMessagesIds: params.extraResults ? [...excludeMessagesIds, ...newMessagesIds] : undefined,
      messagesIds: params.extraResults ? params?.messagesIds : undefined,
      signal,
    });
    return response.data;
  },
);

const closeDmChannel = createAsyncThunk('chatPage-v2/closeDmChannel', async (
  data: {
    channelId: number;
  },
) => {
  const { channelId } = data;
  try {
    await channelApi_V2.closeDmChannel({ channelId });
  } catch (err) {
    console.error('Failed to close a channel:', err);
    toast.error(t('errors:server.internal.unexpected'));
  }
});

export default {
  getMyChannels,
  markMessageAsRead,
  markMessageAsUnread,
  getChannelData,
  loadMoreMessages,
  sendMessage,
  deleteMessage,
  toggleMessagePinStatus,
  editMessage,
  uploadFiles,
  openThread,
  toggleReaction,
  getThreadChannelMessages,
  getPinnedChannelMessages,
  closeDmChannel,
};
