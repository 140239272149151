import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import { useChannelMetaData } from '../ChatSidebar/ChannelLink.hooks';
import { useCurrentChannel } from '../../Chat.hooks';
import useSidebarChannelItemContent from './useSidebarActionItemContent';
import useIsDraftInChannel from 'src/utils/hooks/useIsDraftInChannel';

import { ROUTES } from 'src/utils/constants';
import { ChatTypesENUM, type IChannel } from 'src/types/chatTypes';

import SidebarActionItem from './SidebarActionItem';
import ChatBadge from '../ChatBadge';

import { ReactComponent as DraftIcon } from 'src/ui/assets/images/edit-no-border.svg';

const MENTION_BADGE = '@';

type PropsType = {
  channel: IChannel;
  onChannelClick?: (ev: React.MouseEvent, channel: IChannel) => void;
};

const ChannelNavItem: React.FC<PropsType> = (props) => {
  const channelMeta = useChannelMetaData(props.channel.channelId);
  const { title, icon, type } = useSidebarChannelItemContent({ channel: props.channel });

  const currentChannelId = useCurrentChannel()?.channelId;
  const isDraftInChannel = useIsDraftInChannel(props.channel.channelId);

  const isChannel = props.channel.type === ChatTypesENUM.channel;
  const isPrivateChannel = isChannel && props.channel.isPrivate;
  const isCurrentChannel = currentChannelId === props.channel.channelId;

  const hasAvatarInLink = React.useMemo(() => ['direct', 'group', 'notes'].includes(type), [type]);

  const badgeContent = React.useMemo(() => {
    if (channelMeta?.isContainsMention) {
      return MENTION_BADGE;
    }
    if (channelMeta?.unreadMessagesCount) {
      return channelMeta.unreadMessagesCount;
    }
    return '';
  }, [channelMeta]);

  const showCloseButton = React.useMemo(() => {
    const isGroupChannel = props.channel.type === ChatTypesENUM.group;
    const isDmChannel = props.channel.type === ChatTypesENUM.direct && props.channel.userToChannels?.length !== 1;
    return (isGroupChannel || isDmChannel) && !badgeContent;
  }, [props.channel.type, props.channel.userToChannels?.length, badgeContent]);

  const handleLinkClick = (ev: React.MouseEvent) => {
    props.onChannelClick?.(ev, props.channel);
  };

  const isShowDraftIcon = isDraftInChannel && !isCurrentChannel;

  const isDraftIconHiddenOnHover = (type === 'group' || type === 'direct') && isShowDraftIcon;

  return (
    <StyledWrapper isDraftIconHiddenOnHover={isDraftIconHiddenOnHover}>
      <SidebarActionItem
        onAction={handleLinkClick}
        title={title}
        icon={icon}
        navigateTo={ROUTES.chat.createPath(props.channel.channelId)}
        channelId={props.channel.channelId}
        showCloseButton={showCloseButton}
        linkItemClassName={cn({ 'sidebar-link__with-avatar': hasAvatarInLink })}
        linkItemActiveClassName={cn({ 'sidebar-link__with-avatar--active': hasAvatarInLink })}
        hasBadge={!!badgeContent}
        actionBadge={
          (<div className="badge-container">
            {isShowDraftIcon && (
              <div className="badge-container__draft-icon">
                <DraftIcon />
              </div>
            )}

            {badgeContent && (
              <ChatBadge
                className="badge"
                content={badgeContent}
                isMentionBadge={badgeContent === MENTION_BADGE}
              />
            )}
           </div>)
        }
        asPrivateAction={isPrivateChannel}
        preventActionOnActive
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<{ isDraftIconHiddenOnHover?: boolean }>`
  &:hover {
    .badge-container__draft-icon {
        display: ${({ isDraftIconHiddenOnHover }) => (isDraftIconHiddenOnHover ? 'none' : 'block')};
      }
  }
  
  .badge-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .sidebar-link__with-avatar {
    padding-left: 8px;

    &--active {
      padding-left: 6px;
    }
  }
`;

export default ChannelNavItem;
